<template>
  <div class="home-container">
    <section class="home-hero">
      <header data-thq="thq-navbar" class="home-navbar">
        <img alt="image" src="/logo.svg" class="home-image" />
        <div data-thq="thq-navbar-nav" data-role="Nav" class="home-desktop-menu">
          <nav data-thq="thq-navbar-nav-links" data-role="Nav" class="home-nav">
            <a href="#uslugi" class="home-link navLink">Domy i mieszkania</a>
            <a href="#uslugi" class="home-link1 navLink">Biura</a>
            <a href="#uslugi" class="home-link2 navLink">Po remoncie</a>
            <a href="#okna" class="home-link3 navLink">Mycie okien</a>
            <a
              href="tel:+48796128215"
              class="home-register button button-style-2"
            >
              <span class="home-text">📞 796 128 215</span>
            </a>
          </nav>
        </div>
      </header>
    </section>
    <section class="home-notes">
      <div class="home-first">
        <div data-aos="fade-up-right" class="home-content">
          <h2 class="home-title">Usługi sprzątające</h2>
          <p class="home-caption">
            Zmęczony ciągłym utrzymaniem porządku w domu, biurze czy po ukończonym
            remoncie? Czy może po prostu chcesz poświęcić więcej czasu na coś
            innego zamiast na sprzątanie?
            <span v-html="rawabtv"></span>
          </p>
          <a href="tel:+48796128215" class="home-register1 button button-style-2">
            <span class="home-text01">📞 796 128 215</span>
          </a>
        </div>
        <img
          alt="image"
          src="https://images.unsplash.com/photo-1556912998-38edc44f64da?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDYyfHxjbGVhbmluZ3xlbnwwfHx8fDE2OTc3MTAxODN8MA&amp;ixlib=rb-4.0.3&amp;w=1400"
          class="home-image1 image-notes"
        />
      </div>
    </section>
    <section id="uslugi" class="home-why">
      <div class="home-header">
        <div class="home-section-numeral"><div class="home-divide"></div></div>
        <div data-aos="fade-right" class="home-heading">
          <h2 class="home-title1">Zakres usług</h2>
          <p class="home-caption1">
            Jakiekolwiek by nie były Twoje potrzeby sprzątające, EdiCleaning jest
            po to, aby je zaspokoić. Posiadamy ofertę usług oraz zdolni jesteśmy
            dopasować się do Twoich wymagań.
            <span v-html="raw4ui7"></span>
          </p>
        </div>
      </div>
      <div class="home-brands">
        <div class="home-row">
          <div class="home-item">
            <div class="home-details">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1642505172378-a6f5e5b15580?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDI0fHxjbGVhbmluZ3xlbnwwfHx8fDE2OTkwMzM5NjN8MA&amp;ixlib=rb-4.0.3&amp;h=300"
                class="home-image2"
              />
              <h3 class="home-title2">
                <span>Sprzątanie mieszkań oraz </span>
                <span>domów</span>
              </h3>
              <p class="home-description">
                Twoje cztery kąty są miejscem, gdzie chcesz czuć się komfortowo i
                spokojnie. Wiemy, ile trudu kosztuje utrzymanie czystości w domu,
                szczególnie pośród codziennego chaosu. Dlatego oferujemy
                kompleksowe usługi sprzątające, dopasowane do Twoich potrzeb, abyś
                mógł cieszyć się czystym i spokojnym domem bez wysiłku.
              </p>
            </div>
          </div>
          <div class="home-item1">
            <div class="home-details1">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1527515637462-cff94eecc1ac?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxjbGVhbmluZ3xlbnwwfHx8fDE2OTkwMzM5NjN8MA&amp;ixlib=rb-4.0.3&amp;h=300"
                class="home-image3"
              />
              <h3 class="home-title3">Sprzątanie po remontach</h3>
              <p class="home-description1">
                Każdy, kto przeszedł przez remont, wie jak uciążliwe może być
                porządkowanie po nim. Kurz i brud dosłownie wszędzie, a do tego
                zmęczenie po długim okresie remontowych prac. Dlatego oferujemy
                usługi sprzątania po remontach, byś mógł cieszyć się nową
                przestrzenią bez konieczności sprzątania.
              </p>
            </div>
          </div>
          <div class="home-item2">
            <div class="home-details2">
              <img
                alt="image"
                src="https://images.unsplash.com/photo-1571624436279-b272aff752b5?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDEzfHxjbGVhbmluZyUyMG9mZmljZXxlbnwwfHx8fDE2OTkwMzM5OTh8MA&amp;ixlib=rb-4.0.3&amp;h=300"
                class="home-image4"
              />
              <h3 class="home-title4">Sprzątanie biur</h3>
              <p class="home-description2">
                <span>
                  Właściwe środowisko pracy jest niezbędne do efektywnej i
                  satysfakcjonującej produkcji. Lecz nierzadko zdarza się, że
                  biura stają się miejscami, w których trudno się skoncentrować -
                  biurko pełne papierów, podłogi zaśmiecone... My zajmiemy się
                  tym, by Twoje biuro stało się miejscem, które sprzyja twórczości
                  i efektywnemu działaniu.
                  <span v-html="rawnwsk"></span>
                </span>
                <span><span v-html="raw9o2q"></span></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="home-first1">
        <div id="okna" class="home-details3">
          <img
            alt="image"
            src="https://images.unsplash.com/photo-1509644851169-2acc08aa25b5?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fHdpbmRvd3xlbnwwfHx8fDE2OTk0NzY3NjF8MA&amp;ixlib=rb-4.0.3&amp;w=1400"
            class="home-image5"
          />
          <h3 class="home-title5">Mycie okien w domach i biurach</h3>
          <p class="home-description3">
            Abyś mógł cieszyć się krystalicznie przejrzystymi widokami bez trudu.
            Nasi specjaliści szybko i skutecznie usuną wszelkie ślady brudu, dając
            Ci jeden mniej problem do rozwiązania. Zapewniamy, że po naszej
            interwencji każde okno będzie błyszczeć czystością, podnosząc estetykę
            i funkcjonalność Twojej przestrzeni.
          </p>
        </div>
      </div>
    </section>
    <section class="home-quote"></section>
    <footer class="home-footer">
      <div class="home-content1">
        <div class="home-details4">
          <h2 class="home-title6">EdiCleaning</h2>
          <p class="home-caption2">
            EdiCleaning zajmuje się sprzątaniem domów, biur oraz miejsc po
            remontach. Dzięki naszym usługom, nie musisz martwić się o utrzymanie
            czystości i porządku - zajmiemy się tym za Ciebie, pozwalając Ci
            skupić się na tym, co naprawdę ważne.
          </p>
          <p class="home-caption3">
            Edyta Kątnik · 796 128 215 · edytakatnik@gmail.com
          </p>
          <p class="home-caption4">© 2023 EdiCleaning</p>
        </div>
      </div>
    </footer>
    <div>
      <div class="home-container2">
        <DangerousHTML
          html="<script>
      ScrollReveal().reveal('.speaker');
      ScrollReveal().reveal('.image-notes');
  </script>"
        ></DangerousHTML>
      </div>
    </div>
    <div>
      <div class="home-container4">
        <DangerousHTML
          html="<style>
  #scrollbar::-webkit-scrollbar-track
  {
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  	border-radius: 10px;
  	background-color: #F5F5F5;
  }
  
  #scrollbar::-webkit-scrollbar
  {
  	height: 12px;
  	background-color: #F5F5F5;
  	border-radius: 10px;
  }
  
  #scrollbar::-webkit-scrollbar-thumb
  {
  	border-radius: 10px;
  	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  	background-color: #555;
  }
  </style>"
        ></DangerousHTML>
      </div>
    </div>
    <div data-thq="thq-burger-menu" class="home-burger-menu">
      <svg viewBox="0 0 1024 1024" class="home-icon">
        <path
          d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"
        ></path>
      </svg>
    </div>
    <div data-thq="thq-mobile-menu" class="home-mobile-menu">
      <div data-thq="thq-mobile-menu-nav" data-role="Nav" class="home-nav1">
        <div class="home-container5">
          <h2 class="home-text06">OnConf</h2>
          <div data-thq="thq-close-menu" class="home-menu-close">
            <svg viewBox="0 0 1024 1024" class="home-icon02">
              <path
                d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"
              ></path>
            </svg>
          </div>
        </div>
        <nav
          data-thq="thq-mobile-menu-nav-links"
          data-role="Nav"
          class="home-nav2"
        >
          <span class="navLink home-text07">About</span>
          <span class="navLink home-text08">Features</span>
          <span class="navLink home-text09">Pricing</span>
          <span class="navLink home-text10">Team</span>
          <span class="navLink home-text11">Blog</span>
        </nav>
        <div class="home-button-container">
          <button class="home-login button">Login</button>
          <button class="button home-register2">Register</button>
        </div>
        <div class="home-icon-group">
          <div class="home-icons">
            <svg viewBox="0 0 950.8571428571428 1024" class="home-icon04">
              <path
                d="M925.714 233.143c-25.143 36.571-56.571 69.143-92.571 95.429 0.571 8 0.571 16 0.571 24 0 244-185.714 525.143-525.143 525.143-104.571 0-201.714-30.286-283.429-82.857 14.857 1.714 29.143 2.286 44.571 2.286 86.286 0 165.714-29.143 229.143-78.857-81.143-1.714-149.143-54.857-172.571-128 11.429 1.714 22.857 2.857 34.857 2.857 16.571 0 33.143-2.286 48.571-6.286-84.571-17.143-148-91.429-148-181.143v-2.286c24.571 13.714 53.143 22.286 83.429 23.429-49.714-33.143-82.286-89.714-82.286-153.714 0-34.286 9.143-65.714 25.143-93.143 90.857 112 227.429 185.143 380.571 193.143-2.857-13.714-4.571-28-4.571-42.286 0-101.714 82.286-184.571 184.571-184.571 53.143 0 101.143 22.286 134.857 58.286 41.714-8 81.714-23.429 117.143-44.571-13.714 42.857-42.857 78.857-81.143 101.714 37.143-4 73.143-14.286 106.286-28.571z"
              ></path></svg
            ><svg viewBox="0 0 877.7142857142857 1024" class="home-icon06">
              <path
                d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"
              ></path></svg
            ><svg viewBox="0 0 602.2582857142856 1024" class="home-icon08">
              <path
                d="M548 6.857v150.857h-89.714c-70.286 0-83.429 33.714-83.429 82.286v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571c0-144.571 88.571-223.429 217.714-223.429 61.714 0 114.857 4.571 130.286 6.857z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <img alt="image" src="/quote-background.svg" class="home-background" />
    </div>
  </div>
</template>

<script>
import DangerousHTML from 'dangerous-html/vue'

export default {
  name: 'Home',
  components: {
    DangerousHTML,
  },
  data() {
    return {
      rawabtv: ' ',
      raw4ui7: ' ',
      rawnwsk: ' ',
      raw9o2q: ' ',
    }
  },
  metaInfo: {
    title: 'EdiCleaning',
    meta: [
      {
        name: 'description',
        content:
          'Zmęczony ciągłym utrzymaniem porządku w domu, biurze czy po ukończonym remoncie?',
      },
      {
        property: 'og:title',
        content: 'EdiCleaning',
      },
      {
        property: 'og:description',
        content:
          'Zmęczony ciągłym utrzymaniem porządku w domu, biurze czy po ukończonym remoncie? Czy może po prostu chcesz poświęcić więcej czasu na coś innego zamiast na sprzątanie?',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/3ca0536e-7136-49c9-bac7-06f3912ba5d7/1aaa2541-8658-4490-a021-da7d700eb4a4?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-hero {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  border-color: rgba(0, 0, 0, 0.04);
  border-width: 1px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-twounits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: flex-start;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: 1282px;
  align-items: center;
  justify-content: space-between;
}
.home-image {
  width: 172px;
  height: 89px;
  object-fit: cover;
}
.home-desktop-menu {
  gap: var(--dl-space-space-threeunits);
  display: flex;
}
.home-nav {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-link {
  color: rgb(11, 12, 13);
  font-size: 18px;
  text-decoration: none;
}
.home-link1 {
  color: #0B0C0D;
  font-size: 18px;
  text-decoration: none;
}
.home-link2 {
  color: #0B0C0D;
  font-size: 18px;
  text-decoration: none;
}
.home-link3 {
  color: rgb(11, 12, 13);
  font-size: 18px;
  text-decoration: none;
}
.home-register {
  text-decoration: none;
}
.home-text {
  font-size: 18px;
}
.home-notes {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}
.home-first {
  width: 100%;
  height: 580px;
  display: flex;
  max-height: 80vh;
  align-items: center;
  flex-direction: row;
}
.home-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-self: center;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.home-title {
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}
.home-caption {
  font-size: 24px;
  line-height: 140%;
}
.home-register1 {
  text-decoration: none;
}
.home-text01 {
  font-size: 28px;
}
.home-image1 {
  width: 50%;
  object-fit: cover;
  aspect-ratio: 1;
}
.home-why {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-header {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
}
.home-section-numeral {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
}
.home-divide {
  width: 100px;
  height: 1px;
  display: flex;
  margin-top: 4px;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
}
.home-heading {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-title1 {
  font-size: 90px;
  font-style: normal;
  font-weight: 600;
  line-height: 100px;
}
.home-caption1 {
  font-size: 24px;
  line-height: 140%;
}
.home-brands {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-row {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-self: center;
  align-items: flex-start;
  flex-direction: row;
}
.home-item {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 364px;
  align-items: flex-start;
  flex-direction: column;
}
.home-details {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image2 {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.home-title2 {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.home-description {
  font-size: 24px;
  line-height: 140%;
}
.home-item1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 364px;
  align-items: flex-start;
  flex-direction: column;
}
.home-details1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image3 {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.home-title3 {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.home-description1 {
  font-size: 24px;
  line-height: 140%;
}
.home-item2 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  max-width: 364px;
  align-items: flex-start;
  flex-direction: column;
}
.home-details2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image4 {
  width: 100%;
  height: 300px;
  object-fit: cover;
}
.home-title4 {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.home-description2 {
  font-size: 24px;
  line-height: 140%;
}
.home-first1 {
  width: 100%;
  height: auto;
  display: flex;
  max-height: 80vh;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-details3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-image5 {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.home-title5 {
  font-size: 50px;
  font-style: normal;
  font-weight: 600;
  line-height: 60px;
}
.home-description3 {
  font-size: 24px;
  line-height: 140%;
}
.home-quote {
  width: 100%;
  height: 400px;
  display: flex;
  overflow: hidden;
  position: relative;
  margin-top: var(--dl-space-space-threeunits);
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-size: auto;
  justify-content: center;
  background-image: url("https://images.unsplash.com/photo-1497366754035-f200968a6e72?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDJ8fGNsZWFuaW5nJTIwb2ZmaWNlfGVufDB8fHx8MTY5OTAzMzk5N3ww&ixlib=rb-4.0.3&w=1500");
  background-position: center;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
}
.home-details4 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.home-title6 {
  font-size: 90px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 100px;
}
.home-caption2 {
  font-size: 24px;
  line-height: 140%;
}
.home-caption3 {
  font-size: 24px;
  line-height: 140%;
}
.home-caption4 {
  font-size: 18px;
  line-height: 140%;
}
.home-container2 {
  display: contents;
}
.home-container4 {
  display: contents;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #ffffff;
}
.home-nav1 {
  display: flex;
  z-index: 20;
  align-items: flex-start;
  flex-direction: column;
}
.home-container5 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-text06 {
  color: rgb(255, 255, 255);
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon02 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-button-container {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-login {
  margin-right: var(--dl-space-space-twounits);
}
.home-icon-group {
  display: flex;
  z-index: 20;
}
.home-icons {
  display: flex;
}
.home-icon04 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon06 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon08 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-background {
  left: 0px;
  right: 0px;
  width: 100%;
  bottom: 0px;
  height: 80%;
  margin: auto;
  position: absolute;
  object-fit: cover;
}
@media(max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-twounits);
  }
  .home-notes {
    height: 1089px;
    max-width: var(--dl-size-size-maxwidth);
  }
  .home-first {
    flex-direction: column;
  }
  .home-content {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-image1 {
    flex: 0 0 auto;
    width: 100%;
    height: 598px;
  }
  .home-row {
    align-self: center;
  }
  .home-item1 {
    display: none;
  }
  .home-first1 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .home-hero {
    height: auto;
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .home-link {
    display: none;
  }
  .home-link1 {
    display: none;
  }
  .home-link2 {
    display: none;
  }
  .home-link3 {
    display: none;
  }
  .home-register {
    width: 100%;
  }
  .home-notes {
    height: 687px;
  }
  .home-content {
    width: 100%;
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  .home-caption {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-register1 {
    width: 100%;
  }
  .home-image1 {
    height: 50%;
  }
  .home-why {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-divide {
    width: 50px;
  }
  .home-heading {
    gap: var(--dl-space-space-unit);
  }
  .home-title1 {
    font-size: 30px;
    line-height: 33px;
  }
  .home-caption1 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-row {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-item {
    gap: var(--dl-space-space-oneandhalfunits);
    width: auto;
    max-width: 100%;
  }
  .home-details {
    gap: var(--dl-space-space-unit);
  }
  .home-image2 {
    height: 200px;
  }
  .home-title2 {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    margin-right: var(--dl-space-space-unit);
  }
  .home-description {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-item1 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: auto;
    display: flex;
    max-width: 100%;
  }
  .home-details1 {
    gap: var(--dl-space-space-unit);
  }
  .home-image3 {
    height: 200px;
  }
  .home-title3 {
    font-size: 24px;
    line-height: 28px;
  }
  .home-description1 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-item2 {
    gap: var(--dl-space-space-oneandhalfunits);
    width: auto;
    max-width: 100%;
  }
  .home-details2 {
    gap: var(--dl-space-space-unit);
    width: auto;
    max-width: auto;
  }
  .home-image4 {
    height: 200px;
  }
  .home-title4 {
    font-size: 30px;
    line-height: 28px;
  }
  .home-description2 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-details3 {
    gap: var(--dl-space-space-unit);
  }
  .home-image5 {
    height: 200px;
  }
  .home-title5 {
    font-size: 24px;
    line-height: 28px;
  }
  .home-description3 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-quote {
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-footer {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-content1 {
    gap: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-title6 {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 33px;
  }
  .home-caption2 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-caption3 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-caption4 {
    width: 90%;
    font-size: 20px;
    line-height: 140%;
  }
  .home-burger-menu {
    display: flex;
  }
  .home-icon {
    fill: white;
  }
  .home-nav1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-container5 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-text06 {
    color: #000000;
  }
  .home-nav2 {
    gap: var(--dl-space-space-unit);
  }
  .home-text07 {
    color: #000000;
  }
  .home-text08 {
    color: rgb(0, 0, 0);
  }
  .home-text09 {
    color: rgb(0, 0, 0);
  }
  .home-text10 {
    color: rgb(0, 0, 0);
  }
  .home-text11 {
    color: rgb(0, 0, 0);
  }
  .home-button-container {
    gap: var(--dl-space-space-unit);
    width: 50%;
    margin-top: 0px;
    align-items: flex-start;
    flex-direction: column;
  }
  .home-login {
    width: 100%;
    border-width: 1px;
    margin-right: 0px;
  }
  .home-register2 {
    width: 100%;
    border-width: 1px;
  }
  .home-icon-group {
    width: 100%;
    justify-content: flex-start;
  }
}
@media(max-width: 479px) {
  .home-hero {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  .home-register {
    display: none;
  }
  .home-notes {
    height: 745px;
  }
  .home-title {
    line-height: 140%;
  }
  .home-text01 {
    font-size: 28px;
  }
  .home-divide {
    width: 20px;
  }
  .home-title6 {
    line-height: 140%;
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-button-container {
    width: 100%;
  }
  .home-icon-group {
    justify-content: center;
  }
}
</style>
